<div class="application-view-main-container">
  <div class="application-view-title">Twój koszyk</div>
  <div class="points-amount-label points-amount-label-error" *ngIf="!this.promoCampaignService.campaignActive;">Zakładka będzie aktywna po zakończeniu promocji.</div>
  <div class="points-amount-label points-amount-label-error" *ngIf="didUserPlaceOrder; else noOrderPlacedYet">Na Twoim koncie dokonano już zamówienia.</div>
  <ng-template #noOrderPlacedYet>
    <div class="points-amount-label points-amount-label-custom">
      <span>Przydzielona liczba zł: {{ userAssignedPoints }}.</span>
      <span>Pozostałe zł do wykorzystania: {{ userAvailablePoints }}.</span>
    </div>
  </ng-template>
  <div *ngIf="cartItemsSource.length > 0; else emptyCart"
    class="cart-items-container">
    <cart-item-presenter *ngFor="let item of cartItemsSource"
      [cartItemData]="item"></cart-item-presenter>
  </div>
  <ng-template #emptyCart>
    <div class="cart-empty-label">Twój koszyk jest pusty</div>
  </ng-template>
  <div class="action-buttons-container">
    <button type="button" class="btn btn-sm btn-secondary btn-secondary-custom"
      (click)="goToBenefitsCatalog()">Katalog nagród</button>
    <button type="button" class="btn btn-sm btn-primary btn-primary-custom"
      [disabled]="!isPlaceOrderButtonEnabled" (click)="placeOrder()">Złóż zamówienie</button>
  </div>
</div>
<waiting-spinner *ngIf="isSpinnerVisible" [text]="'Składanie zamówienia...'"></waiting-spinner>
<alert></alert>