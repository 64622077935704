import { Injectable } from '@angular/core';
import { UserDataService } from '../services/user-data.service';
import { ReportSaveService } from './report-save.service';

@Injectable({
  providedIn: 'root'
})
export class UnregisteredUsersWithDevicesReportService {

  constructor(
    public userDataService: UserDataService,
    private savingService: ReportSaveService
  ) { }

  public async generateReport() : Promise<void> {
    try {
      const allUsersData = await this.userDataService.getAllExistingUsersData();
      const unregisteredUsersData = await this.getUnregisteredUsersWithDevices(allUsersData);
      const reportData = this.generateReportContent(unregisteredUsersData);
      this.save(reportData);
    } catch(e) {
      console.warn(e);
    }
  }

  private async getUnregisteredUsersWithDevices(usersData: Array<any>) : Promise<Array<any>> {
    const unregisteredUsers: Array<any> = [];
    usersData.forEach(d => {
      if (!d.registered && (d.registeredProducts?.length > 0)) {
        unregisteredUsers.push(d);
      }
    });
    return unregisteredUsers;
  }

  private generateReportContent(rankingArray: Array<any>) : Array<Array<any>> {
    const result: Array<Array<any>> = [];
    result.push(this.generateReportHeader());
    result.push(['']);
    let iterator = 1;
    rankingArray.forEach(u => {
      result.push([u.userEmail]);
      iterator++;
    });
    iterator = 1;
    return result;
  }

  private generateReportHeader() : Array<string> {
    return ['Adres email'];
  }

  private save(data: Array<Array<any>>) : void {
    this.savingService.saveXlsxReport(data, 'niezarejestrowani_z_urzadzeniami.xlsx', 'Raport')
  }
}
