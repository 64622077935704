<div class="application-view-main-container application-view-main-container-wider">
  <div class="points-amount-label points-amount-label-error" *ngIf="!this.promoCampaignService.campaignActive;">Zakładka będzie aktywna po zakończeniu promocji.</div>
  <div class="points-amount-label points-amount-label-error" *ngIf="didUserPlaceOrder; else noOrderPlacedYet">Na Twoim koncie dokonano już zamówienia.</div>
  <ng-template #noOrderPlacedYet>
    <div class="points-amount-label text-orange">Stan konta: {{ userPointsAmount }} zł.</div>
  </ng-template>

  <div class="description-container">
    <p class="landing-page-text-big text-orange text-bold">Wybieraj bez ograniczeń!</p>
    <p class="landing-page-text text-black">Pamiętaj aby wydać wszystkie zdobyte środki i wyzerować <b>STAN KONTA</b>, więcej szans nie będzie!</p>
    <p class="landing-page-text text-black">Zamówienie potwierdzimy mailowo.</p>
    <p class="landing-page-text text-black">O terminie wysyłki nagród poinformujemy komunikatem mailowym oraz sms.</p>
  </div>
  
  <div *ngIf="benefitsSource.length > 0; else noBenefits" class="benefits-list">
    <benefit-presenter *ngFor="let benefit of benefitsSource"
      [benefitData]="benefit"></benefit-presenter>
  </div>
  <ng-template #noBenefits>
    <div class="no-benefit-defined-text">Nie zdefiniowano żadnej nagrody.</div>
  </ng-template>
</div>
<waiting-spinner *ngIf="isSpinnerVisible" [text]="'Wczytywanie...'"></waiting-spinner>
<alert></alert>